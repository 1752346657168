/* globals.scss */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-base-sm md:text-base;
  }

  body {
    @apply bg-body text-base dark:bg-darkmode-body font-normal leading-relaxed text-text dark:text-darkmode-text;
    text-rendering: optimizeSpeed;
    scrollbar-gutter: stable;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold leading-tight text-dark dark:text-darkmode-dark;
  }

  h1,
  .h1 {
    @apply text-h1-sm md:text-h1;
  }

  h2,
  .h2 {
    @apply text-h2-sm md:text-h2;
  }

  h3,
  .h3 {
    @apply text-h3-sm md:text-h3;
  }

  h4,
  .h4 {
    @apply text-h4;
  }

  h5,
  .h5 {
    @apply text-h5;
  }

  h6,
  .h6 {
    @apply text-h6;
  }

  b,
  strong {
    @apply font-semibold;
  }

  code {
    @apply after:border-none;
  }

  blockquote > p {
    @apply my-0;
  }

  .header {
    @apply bg-body py-1 dark:bg-darkmode-body;
  }
}

@layer components {
  /* Theme switcher */
  .theme-switcher {
    @apply inline-flex;

    label {
      @apply relative inline-block h-4 w-6 cursor-pointer rounded-2xl bg-border lg:w-10;
    }

    input {
      @apply absolute opacity-0;
    }

    span {
      @apply absolute -top-1 left-0 dark:lg:left-4 flex h-6 w-6 items-center justify-center rounded-full bg-dark transition-all duration-300 dark:bg-white;
    }

    input:checked + label {
      span {
        @apply lg:left-4;
      }
    }
  }

  .bg-primary-dark {
    background-color: #2b6cb0; /* Darker shade of primary color */
  }

  .bg-primary-darker {
    background-color: #2c5282; /* Even darker shade of primary color */
  }

  .ring-primary-light {
    --tw-ring-color: #90cdf4; /* Lighter shade of primary color */
  }

  .darkmode-primary-dark {
    background-color: #1a202c; /* Darker shade of dark mode primary color */
  }

  .darkmode-primary-darker {
    background-color: #1a1f24; /* Even darker shade of dark mode primary color */
  }

  .darkmode-primary-light {
    --tw-ring-color: #718096; /* Lighter shade of dark mode primary color */
  }

  .container {
    @apply px-4 md:px-20 lg:px-48;
  }

  /* Buttons */
  .btn {
    @apply inline-block rounded border border-transparent px-5 py-2 font-semibold capitalize transition-all duration-300 ease-in-out;
  }

  .btn-primary {
    @apply border-primary bg-primary text-white dark:border-darkmode-primary dark:bg-darkmode-primary dark:text-black;
  }

  /* Hover and Focus Effects */
  .btn-primary:hover {
    @apply bg-primary text-white dark:bg-darkmode-primary;
  }

  .btn-primary:focus {
    @apply outline-none ring-2 ring-primary-light dark:ring-darkmode-primary;
  }

  .btn-primary:active {
    @apply bg-primary-darker dark:bg-darkmode-primary;
  }

  /* Social Links */
  .social-icons {
    @apply space-x-4;
    li {
      @apply inline-block;
      a {
        @apply flex h-9 w-9 items-center justify-center rounded bg-primary text-center leading-9 text-white dark:bg-darkmode-primary dark:text-dark;
        svg {
          @apply h-5 w-5;
        }
      }
    }
  }
}
